function switchImages( newImagePath ){
  var logo = $( "#logo" );

  logo.fadeOut( 500, function() {
    logo.attr( "src", newImagePath );
    logo.fadeIn( 500 );
  });
}


$(document).ready(function(){
  var $window = $(window),
      mainHeader = $("#main-menu"),
      defaultLogo = "assets/img/cammtac_logo.svg",
      smallLogo = "assets/img/cammtac_logo_small.svg";

  $window.scroll( function() {
    if ($window.scrollTop() > 100 ){
      if( !mainHeader.hasClass( "shrink" )){
          mainHeader.addClass( "shrink" );
          switchImages(smallLogo);
          }
        } else{
                if( mainHeader.hasClass( "shrink" )){
                    mainHeader.removeClass( "shrink" );
                    switchImages(defaultLogo);
                }
          }

      });

});

// else{
//         if( mainHeader.hasClass( "shrink" )){
//             mainHeader.removeClass( "shrink" );
//         };
//       }
// }
// })
